<template>
  <div class="z-card">
    <b-card :class="{ 'z-clickable': !disabled, 'selected': !disabled && selected, 'disabled': disabled }">
        <slot></slot>
    </b-card>
  </div>
</template>

<script>
export default {
    props: {
      selected: {
         type: Boolean,
         required: false,
         default: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    }
}
</script>

<style lang="sass">
  .z-card 
    .bigger
        font-size: 140%

    .card
        border-radius: 10px
        padding-top: 35px
        border: 2px solid #fff
        height: 100%

        &:hover    
            border-color: rgba(#76AD63, 0.1)
            background-color: rgba(#76AD63, 0.1)

        .semester
            background: #555
            color: #fff
            padding: 3px 10px
            border-radius: 5px

        h5
            font-weight: 900

        &::after
            display: flex
            justify-content: center
            align-items: center
            content: ''
            width: 26px
            height: 26px
            position: absolute
            right: 10px
            top: 10px
            border-radius: 50%
            background-color: #E8E4DA

        &.selected
            border: 2px solid #76AD63
            background-color: rgba(#76AD63, 0.25) !important

            &::after
                border: 1px solid #8FC16B
                background: #E8E4DA url('./img/check-input.svg') center no-repeat

        &.disabled
            border: 2px solid rgba(194, 10, 10, 0.3) !important
            background-color: rgba(194, 10, 10, 0.15) !important
            cursor: not-allowed !important

            &::after
                border: 1px solid rgba(194, 10, 10, 0.3) !important
                background: #E8E4DA url('./img/x-input.svg') center no-repeat

</style>